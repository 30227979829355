body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.container_flex {
  display: flex; /* or inline-flex */
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  padding: 25px;
  background: white;
}
/*--------------------------*/

/*re table elements, such as in login:*/
/*
th, td {
  padding: 5px;
  text-align: right;    
}
*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

/*20191129*/
.display-linebreak {
  white-space: pre-line;
}

.display-inline-span{
    display: inline-block;  /*makde it centered, otherwise flushed left*/
  }

.App {
  text-align: center;
}

.App-logo-static {
  height: 60px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.float_right_align_right{
  float: right;
  text-align: right;
}

.container_for_fixed_n_flex_divs{
  display: flex;
}

.fixed{
  width: 24rem;
}

.flex-item{
  flex-grow: 1;
}


.App-header {
  background-color: #222;
  text-align: center;
  height: 90px;
  padding: 15px;
  color: white;
}

.App-intro {
  font-size: large;
}

.App-intro {
  font-size: large;
}

.p {
  display: block;
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

/* az... */

.button_left {
  display: flex;
  justify-content: space-between;
  width: 20rem;
  border: .1rem red solid;
}

.button_margin_right {
  margin-right: .5rem;
}

.button_margin_right_bottom {
  margin-right: .5rem;
  margin-bottom: .5rem;
}

/* for displaying linebread in string variable in <p> or <div> 20190213az*/
.display-linebreak {
  white-space: pre-line;
}

.ui_msg_label{
  color: red;
  font-size: small
}

.btn-primary-spacing 
{
margin-left: 10px;
margin-top: 10px;
margin-bottom: 10px !important;
}

.btn-space {
  margin-right: .5rem;
  margin-left: .5rem;
}

.btn-space_right {
  margin-right: 1em;
}

.btn-space_align_right {
  margin-right: .5rem;
  margin-left: .5rem;
  align-self: right;
}

.text_align_right {
  text-align: right;
}

.text_align_left {
  text-align: left;
}

.text_small{
  font-size: .75rem
}

.text_bold{
  font-weight: bold;
}

.text_bold_blue{
  font-weight: bold;
  color: blue
}

.text_blue{
  color: blue
}

.text_italic{
  font-style: italic;
}

.col_label_name {
    /*width:190px;*/
    width:25%;
    /*clear: both; */ 
    border:none;
    padding-right:10px;
    margin-right:5px;
    margin-left:10px;
    font-weight: bold;
    float: left;
}

.label_leftish {
    /*width: 20%;*/
    clear: both;
    padding-right:10px;
    margin-right:5px;
    margin-left:10px;
    font-weight: normal;
}

.label_leftish_bold {
  /* Other styling.. */
  width: 20%;
  clear: both; 
  padding-right:10px;
  margin-right:5px;
  margin-left:10px;
  font-weight: bold;
}

.password {
  font-weight: bold;
   cursor: pointer;
    position: absolute;
        display: block;
        background-color : #99FFCC;
}

.container_generic_background_white {
  background-color : white;
}

/*----------------------*/
/* UserLanding*/



/*----------------------*/
/*for person, context, dynamics...*/

.menu_brand_text {
font-size: .9rem;
font-weight: bold
}

.containerSignUpForIntroPres {
  background-color: white;
  margin: 0 auto;
  width: 600px;
  /*border: 4px solid  rgb(191, 219, 223);*/
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 30px;
  padding-bottom: 5px;
  margin-top: 15px;
}

.containerSignUpForIntroPresL {
  background-color: white;
  margin: 0 auto;
  width: 600px;
  border: 4px solid  rgb(191, 219, 223);
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 30px;
  padding-bottom: 5px;
  margin-top: 15px;
}


.containerRegisterUser {
  background-color: white;
  margin: 0 auto;
  width: 600px;
  border: 4px solid  rgb(191, 219, 223);
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 30px;
  padding-bottom: 5px;
  margin-top: 15px;
}

.containerRegisterUser__input_tell_ab_yrself {
  width: 400px;
}

.containerLandingPage {
  background-color: lightblue;
  margin: 0 auto;
  width: 400px;
  border: 4px solid  rgb(191, 219, 223);
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 30px;
  padding-bottom: 5px;
  margin-top: 15px;
}

.containerSignUpForIntroPresL__OptionList {
  padding-left: 20px;
}

.containerRegNewUser__ui_msg_label {
  padding-right: 5px;
  color: red;
  font-size: small
}

.containerSignUpForIntroPresL__msg_saved_success {
  font-weight: bold;
  color: blue
}

.containerLogin {
  /*background-color: lightblue;*/
  margin: 0 auto;
  width: 400px;
  /*border: 1px solid #000;*/
  /*border: 4px solid  rgb(191, 219, 223);*/
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 30px;
  padding-bottom: 5px;
  margin-top: 15px;
}

.userTypeDisplay {
  margin: 0;
  /*width: 600px;*/
  margin-top: 1rem;
  color: red;
  font-weight: bold;
}

.displayFontRed {
  color: red;
}

.displayFontBold {
  font-weight: bold;
}

.userAppNameDisplay {
  margin: 0 auto;
  width: 600px;
  /*margin-top: 1rem;*/
  margin-bottom: 1rem;
  color: blue;
  /*font-weight: bold;*/
}

.userAppNameDisplayAsLink {
  text-decoration: underline;
  /*margin: 0 auto;*/
  /*width: 600px;*/
  /*margin-top: 1rem;*/
  margin-bottom: 1rem;
  margin-right: .5rem;
  margin-left: .5rem;
  color: blue;
  font-weight: bold;
  /*float:right;*/
}

/*https://www.w3schools.com/howto/howto_css_responsive_iframes.asp*/
.container_for_resp_iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}



.userTypeDisplayShort {
  margin: 20px;
  width: 10em;
  margin-top: 0px;
  color:white;
  background-color: red;
  font-size: smaller;
  /*font-weight: bold;*/
}

.label_no_margins{
  margin: 0px
}



.userLanding_main_buttons {
  width: 12em;
}
.userLanding_main_all_buttons {
  width: 6em;
  margin-left: 1em;
}
.userLanding_main_wide_buttons {
  width: 19em;
}

.container_persons {
  /*width: 90%;*/
  border: 3px solid #000;
}
.container_persons__div_top{
  margin: 0 auto
}

.container_persons__input_txt_name {
  width: 10rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.containerFormPerson_even{
  background-color: rgb(191, 219, 223);
  margin: 0 auto;
  /*width: 600px;*/
  border: opx solid  rgb(191, 219, 223);
  /*border: 1px solid #000;*/
  border-left: 3px solid rgb(191, 219, 223);
  border-right: 3px solid rgb(191, 219, 223);
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 30px;
  padding-bottom: 5px;
}

.containerFormPerson_odd{
  background-color: white;
  margin: 0 auto;
  /*width: 600px;*/
  border: 0px solid  rgb(191, 219, 223);
  /*border: 1px solid #000;*/
  border-left: 3px solid rgb(191, 219, 223);
  border-right: 3px solid rgb(191, 219, 223);
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 30px;
  padding-bottom: 5px;
}

.input_in_list_person_info_even{
  background-color: rgb(191, 219, 223);
  border: 0;
  border-radius: 0;
  background: none;
  -webkit-appearance: none;
}

.input_in_list_person_info_odd{
  background-color:white;
  border: 0;
  border-radius: 0;
  background: none;
  -webkit-appearance: none;
}

.containerHeaderUtilites{
    background-color: white;
    border: 3px solid  rgb(191, 219, 223);
  }

/*
.container_persons:nth-child(odd) {
  background: red;
}
.container_persons:nth-child(even) {
  background: blue;
}
*/

/* DELCAND_20180108
.container {
  width: 90%;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #000;
  padding-top: 15px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 15px;
}
*/

.small_lable {
  font-size: 9px;
  margin-left: 0px;
}

/* az need to check where this is*/
.input_pos_specifics {
  width: 1000px;
}

.containerPersonalDetails {
  margin: 0 auto;
  text-align: left;
  width: 700px;
  border: 3px solid  rgb(191, 219, 223);
  margin-top: 1.5rem;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 15px;
}

.containerPersonalDetails label{
  border: 0px solid #000;
  font-size: smaller;
}

.inputPersonDetails {
  width: 95%;
  margin-left: 1rem;
  margin-bottom: 1rem;
  border:  1px solid	rgb(211,211,211);
  box-sizing: border-box;
}

.textAreaPersonDetails {
  width: 95%;
  margin-left: 1rem;
  margin-bottom: 1rem;
  border: 1px solid	rgb(211,211,211);
  height: 150px;  
}

.hr_divider
{ 
  color: rgb(191, 219, 223);;
  background-color: rgb(191, 219, 223);
  height: 1px;
  padding: 0px;
  margin: 0px;
} 


.containerPersonContexts {
  margin: 0 auto;
  text-align: left;
  /*display: inline-block;  /*makde it centered, otherwise flushed left*/
  width: 95%;
  border: 3px solid  rgb(191, 219, 223);
  margin-top: 1.5rem;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 15px;
}

.containerPersonContext{
  margin: 0 auto;
  text-align: left;
  /*display: inline-block;  /*makde it centered, otherwise flushed left*/
  width: 690px;
  /*border: 3px solid  rgb(191, 219, 223);*/
  margin-top: 1.5rem;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 15px;
}

.containerPersonContext_TESTING {
  margin: 0 auto;
  width: 700px;
  display: inline-block;  /*makde it centered, otherwise flushed left*/
}

.containerPersonContext label {
  font-size: smaller;
}

.dropdownPersonContext {
  height: 20px;
  width: 100%;
  border: 0px solid rgb(9, 110, 80);
  margin-top: 5px;
  margin-right:2cm;
  margin-bottom: 25px;
  font-weight: bold;
}

.inputPersonContext {
  width: 95%;
  margin-left: 15px;
  border: 0px solid #000;
}

.textAreaPersonContext {
  margin-left: 15px;
  margin-top: 15px;
  border: 1px solid 	rgb(211,211,211);
  height: 4rem;  
}

.textAreaPersonContextShort {
  margin-left: 15px;
  border: 1px solid 	rgb(211,211,211);
  height: 2rem;  
}

.containerPersonContextButtonCopyContextAsPartOfHeadContextGroup {
  margin-right: 1rem;
}

.inputPersonContextDeleteEnable {
  width: 15rem;
  margin-top: 1rem;
  border: 2px solid rgb(0, 153, 255);
}

.containerContextDynamicGroupTopHeaderSection{
  background-color: black;
  margin: 0 auto;
}

.containerContextDynamicGroup {
  margin: 0 auto;
  width: 400px;
  margin-top: 0px;
  border: 3px solid  rgb(191, 219, 223);
  margin-top: 1.5rem;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 15px;
}

.p_containerContextDynamicGroup{
  display: block;
  text-align: left;
  margin-top: 0.5em;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.containerContextDynamicUnit {
  /*display: inline-block;  *//*makde it centered, otherwise flushed left*/
  width: 390px;
  /*
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 0px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 0px;
  */
}

.containerContextDynamicUnit__exec_label {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  text-align: left;
  color: black
}
/*re to above: 
  display: inline-block;
  vertical-align: middle;
align label with select next to it
*/

.containerContextDynamicUnit__dropdownDyanmicPhunkArea {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 200px;
  border: 0px solid  blue;
  margin-top: 5px;
  margin-right:5px;
  margin-left: 15px;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1rem;
  padding: 0px
}

.containerContextDynamicUnit__label_area {
  color: blue;
  font-size: large;
  font-weight: bold;
  margin-left: 2rem;
  padding-bottom: 0rem;
}

.containerContextDynamicUnit__label_area_previous {
  font-style: italic;
  margin-left: 10px;
}

/*not used:*/
.containerContextDynamicUnit__label_mirror_area_diplay_visible {
  visibility: visible;
  /*width: 1rem;*/
  font-style: italic;
  font-size: small
}

.containerContextDynamicUnit__label_mirror_area_diplay_hidden {
  visibility: hidden
}

.containerContextDynamicUnitButton__resetArea {
  width: 50px;
  height: 50px;
  display: inline-block;
}
.containerContextDynamicUnitButtons {
  width: 700px;
  /*text-align: left;*/
  margin-left: 15px;
  margin-right: 15px;
  border: 0px;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 15px;
}

.textAreaContextDynamicUnit {
  width: 95%;
  margin-left: 15px;
  border: 0px solid #000;
  height: 40px;  
}
 
.dropdownDyanmicPhunkCat {
  height: 20px;
  width: 200px;
  border: 0px solid rgb(9, 110, 80);
  margin-top: 5px;
  margin-right:2cm;
  margin-bottom: 15px;
  font-weight: bold;
}

.containerRecommendationGroup {
  margin-left: 0 auto;
  /*width: 400px;*/
  margin-top: 0px;
  border: 3px solid  rgb(191, 219, 223);
  margin-bottom: 1.5rem;
}

.containerRecommendationGroupToggleText {
  font-size: small;
}

.containerRecommendationBasic {
  width: 393px;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  /*border: 2px solid  rgb(191, 219, 223);*/
  padding-top: 5px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
}

.recommendationUnitCardHeader {
  border-bottom: 3px solid
}

.recommendationUnitCardBodyElement {
  padding-bottom: 0px
}


.containerRecommendationBasic__rec_text{
  margin: 0px;
  border: 0px;
}

.containerRecommendationBasic__rec_text_more_desc_level_elem{
  margin: 30px;
  font-style: italic;
}

.containerRecommendationBasic__rec_text_more_desc_level_01{
  margin: 30px;
  font-style: normal;
}

.containerRecommendationBasic__rec_text_more_desc_level_02{
  margin: 15px;
  font-size: smaller;
}

.containerRecommendationBasic__button_more_desc{
  margin: 5px;
  margin-bottom: 10px;
  float: left;
}

.containerRecommendationBasic__recmdActionContentInput {
  width: 100%;
  height: auto;
  min-height: 75px;
  border: 1px solid;
  border-color: rgb(211,211,211);
}

.recmd_basic_backcolor_stop {
  background-color: red;
  font-weight: bold;
  font-size: 14pt;
  display:inline-block;
}

.recmd_basic_backcolor_caution{
  background-color: yellow;
  font-weight: bold;
  font-size: 14pt;
  display:inline-block;
}

.recmd_basic_backcolor_deliver{
  background-color: #00FFFF;
  font-weight: bold;
  font-size: 14pt;
  display:inline-block;
}

.recmd_basic_backcolor_present{
  background-color: #ADFF2F;
  font-weight: bold;
  font-size: 14pt;
  display:inline-block;
}

.recmd_basic_action_image{
  margin-right: 1rem
}

.recmd_basic_action_text_div{
  margin-bottom: 1rem;
}

.recmd_basic_action_text{
  font-weight: bold;
  margin-left: 2rem;
}

.recmd_basic_action_text_not_bold_for_cancat{
  font-weight: normal;
  /*margin-right: 2rem;*/
  margin-bottom: 3rem;
}

/*DELCAND_20171129, not used*/
.inputContextDynamicUnit {
  height: 20px;
  width: 100%;
  border: 1px solid #000;
  margin-top: 5px;
  margin-right:2cm;
  /*margin-right:15px;*/
  /*margin-left:15px;*/
  margin-bottom: 15px;
  font-weight: normal;
}

fieldset{
  border:none;
  /*width:1000px;*/
  padding-bottom: 5px;
  padding-top: 5px;
}


